import React, { ChangeEvent, KeyboardEvent } from "react";
import { Block, Wrapper } from "../styles";
import { Checkbox, Chip, FormControlLabel, Grid, Tooltip } from "@mui/material";
import Label from "../../../FormComponents/Label";
import CustomSelect from "shared/ui/Select";
import FormGroup from "../../../FormComponents/FormGroup";
import { InputGroup, CssTextField } from "./styles";
import { TenantItem } from "shared/api/tenant/types";

interface ErrorsProps {
  name?: boolean;
  provider?: boolean;
  location?: boolean;
  distributionType?: boolean;
  channel?: boolean;
  tenant?: boolean;
}

interface FormProps {
  name: string;
  provider: string;
  location: string;
  distributionType: string;
  channel: string;
  allowedDomains: string[];
  scopes: string[];
  tenant: string;
  scope: string;
  isPublic: boolean;
  setIsPublic: () => void;
  valueEntered: string;
  errors: ErrorsProps;
  setValueEntered: (event: any) => void;
  setScope: (event: any) => void;
  deleteValue: (value: string, type: string) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleKeyPress: (event: KeyboardEvent<HTMLInputElement>) => void;
  tenants: TenantItem[];
}

const distributionTypes = ["Network", "Direct"];

const DistributionForm = ({
  name,
  provider,
  location,
  distributionType,
  channel,
  allowedDomains,
  scopes,
  tenant,
  isPublic,
  setIsPublic,
  scope,
  setScope,
  valueEntered,
  errors,
  tenants,
  handleChange,
  deleteValue,
  setValueEntered,
  handleKeyPress,
}: FormProps) => {
  return (
    <Wrapper>
      <Block>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormGroup
              placeholder="-"
              value={name}
              id="name"
              onChange={handleChange}
              label="Distribution Name"
              error={errors?.name}
              danger
            />
          </Grid>
          <Grid item xs={6}>
            <FormGroup
              placeholder="-"
              value={provider}
              id="provider"
              onChange={handleChange}
              label="Provider"
              error={errors?.provider}
              danger
            />
          </Grid>
          <Grid item xs={6}>
            <FormGroup
              placeholder="-"
              value={location}
              id="location"
              onChange={handleChange}
              label="Location"
              error={errors?.location}
              danger
            />
          </Grid>
          <Grid item xs={6}>
            <Label label="Distribution Type" danger={true} />
            <CustomSelect
              value={distributionType}
              id="distributionType"
              options={distributionTypes}
              onChange={handleChange}
              errorPlaceholder="Choose Distribution Type"
              error={errors?.distributionType}
            />
          </Grid>
          <Grid item xs={6}>
            <Label label="Channel" danger />
            <CustomSelect
              value={channel}
              id="channel"
              options={[
                "Media",
                "DTCMicrosite",
                "Ecommerce",
                "VerticalSoftware",
                "Custom",
                "POS",
                "Social",
              ]}
              onChange={handleChange}
              errorPlaceholder="Choose Channel"
              error={errors?.channel}
            />
          </Grid>
          <Grid item xs={6}>
            <Label label="Tenant" danger />
            <CustomSelect
              value={tenant}
              id="tenant"
              options={tenants.map((item) => item.companyName)}
              onChange={handleChange}
              errorPlaceholder="Choose Tenant"
              error={errors?.tenant}
            />
          </Grid>
          <Grid item xs={12}>
            <Label label="Allow Domains" />
            <Tooltip
              title="Please press Enter button after input the value"
              classes={{ popper: "white-tooltip" }}
            >
              <InputGroup>
                {allowedDomains?.map((value: string) => (
                  <Chip
                    key={value}
                    label={value}
                    onDelete={() => deleteValue(value, "allowedDomains")}
                  />
                ))}
                <CssTextField
                  fullWidth={!allowedDomains.length}
                  type="text"
                  variant="outlined"
                  value={valueEntered}
                  id="allowedDomains"
                  onChange={(e: any) => setValueEntered(e.target.value)}
                  onKeyDown={handleKeyPress}
                  sx={{ padding: 0, border: "none" }}
                />
              </InputGroup>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Label label="Scopes" />
            <Tooltip
              title="Please press Enter button after input the value"
              classes={{ popper: "white-tooltip" }}
            >
              <InputGroup>
                {scopes?.map((value: string) => (
                  <Chip
                    key={value}
                    label={value}
                    onDelete={() => deleteValue(value, "scopes")}
                  />
                ))}
                <CssTextField
                  fullWidth={!scopes.length}
                  type="text"
                  variant="outlined"
                  value={scope}
                  id="scopes"
                  onChange={(e: any) => setScope(e.target.value)}
                  onKeyDown={handleKeyPress}
                  sx={{ padding: 0, border: "none" }}
                />
              </InputGroup>
            </Tooltip>
          </Grid>
          <Grid item xs={6}>
            <div
              className="required-checkbox"
              style={{ float: "left", margin: "auto" }}
            >
              <FormControlLabel
                control={<Checkbox onChange={setIsPublic} />}
                checked={isPublic}
                label="Is Public"
              />
            </div>
          </Grid>
        </Grid>
      </Block>
    </Wrapper>
  );
};

export default DistributionForm;

import React, { ChangeEvent, KeyboardEvent } from "react";
import {
  Block,
  Wrapper,
  Icon,
  Title,
  Description,
  ButtonsGroup,
} from "../styles";
import { AttributesData } from "api/types";
import { Checkbox, Chip, FormControlLabel, Grid, Tooltip } from "@mui/material";
import Button from "../../../Button";
import Label from "../../../FormComponents/Label";
import CustomSelect from "shared/ui/Select";
import FormGroup from "../../../FormComponents/FormGroup";
import successIcon from "assets/icons/successIcon.svg";
import { formatOptions } from "./types";
import { InputGroup, CssTextField } from "./styles";

interface ErrorsProps {
  attributeName?: boolean;
  displayName?: boolean;
  description?: boolean;
  valueType?: boolean;
  fillmentType?: boolean;
  formatType?: boolean;
}

interface SystemField {
  name: string;
  id: string;
}

interface FormProps {
  attribute: AttributesData | undefined;
  attributeName: string;
  displayName: string;
  defaultValue: string;
  description: string;
  regularExpression: string;
  fillmentType: string;
  valueEntered: string;
  format: string;
  valueType: string;
  created: boolean;
  errors: ErrorsProps;
  systemId: SystemField | null;
  checked: boolean;
  isArrayed: boolean;
  fieldNames: string[];
  fillmentTypes: string[];
  values: string[];
  handleClose: () => void;
  handleTypeChange: (event: any) => void;
  handleFormatChange: (event: any) => void;
  handleSystemIdValueChange: (event: any) => void;
  setValueEntered: (event: any) => void;
  handleFillmentChange: (event: any) => void;
  deleteValue: (value: string) => void;
  setChecked: () => void;
  setIsArrayed: () => void;
  resetCreated: () => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleKeyPress: (event: KeyboardEvent<HTMLInputElement>) => void;
}

const AttributeForm = ({
  attribute,
  attributeName,
  displayName,
  defaultValue,
  description,
  regularExpression,
  fillmentType,
  fieldNames,
  created,
  format,
  systemId,
  valueType,
  checked,
  isArrayed,
  fillmentTypes,
  valueEntered,
  errors,
  values,
  handleChange,
  deleteValue,
  resetCreated,
  setChecked,
  setIsArrayed,
  handleClose,
  setValueEntered,
  handleTypeChange,
  handleFormatChange,
  handleFillmentChange,
  handleSystemIdValueChange,
  handleKeyPress,
}: FormProps) => {
  const disabledFillmentOptions = () => {
    if (attribute) {
      if (fillmentType === "SystemContainer") {
        return fillmentTypes.filter((type) => type !== "SystemContainer");
      } else return ["SystemContainer"];
    } else return [];
  };
  return (
    <Wrapper>
      <Block created={created}>
        {created ? (
          <>
            <Icon src={successIcon} alt="" />
            <Title>Attribute has been created successfully</Title>
            <Description>Do you wish to create a new attribute?</Description>
            <ButtonsGroup>
              <Button
                variant="outlined"
                onClick={handleClose}
                data-testid="attributeModal_button"
              >
                No, Close
              </Button>
              <Button
                variant="contained"
                onClick={resetCreated}
                data-testid="attributeModal_button"
              >
                Yes, Create New
              </Button>
            </ButtonsGroup>
          </>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormGroup
                disabled={!!attribute}
                placeholder="-"
                value={attributeName}
                id="attributeName"
                onChange={handleChange}
                label="Attribute Name"
                error={errors?.attributeName}
                danger
              />
            </Grid>
            <Grid item xs={6}>
              <FormGroup
                placeholder="-"
                value={displayName}
                id="displayName"
                onChange={handleChange}
                label="Display Name"
                error={errors?.displayName}
                danger
              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup
                placeholder="-"
                value={description}
                id="description"
                onChange={handleChange}
                label="Description"
                error={errors?.description}
                danger
              />
            </Grid>
            <Grid item xs={6}>
              <Label label="System Field Identifier" />
              <CustomSelect
                value={systemId ? systemId.name : ""}
                id="systemId"
                options={fieldNames}
                onChange={(event) =>
                  handleSystemIdValueChange(event.target.value)
                }
                errorPlaceholder="Choose System Field Identifier"
              />
            </Grid>
            <Grid item xs={6}>
              <FormGroup
                placeholder="-"
                value={regularExpression}
                id="regularExpression"
                onChange={handleChange}
                label="Regular Expression"
              />
            </Grid>
            <Grid item xs={6}>
              <Label label="Fillment Type" danger={true} />
              <CustomSelect
                value={fillmentType}
                id="fillmentType"
                options={fillmentTypes}
                onChange={(event) => handleFillmentChange(event.target.value)}
                errorPlaceholder="Choose Fillment Type"
                error={errors?.fillmentType}
                disabled={attribute?.defaultFillmentType === "SystemContainer"}
                disabledOptions={disabledFillmentOptions()}
              />
            </Grid>
            <Grid item xs={6}>
              <Label label="Value Type" danger />
              <CustomSelect
                value={valueType}
                id="valueType"
                disabled={!!attribute}
                options={[
                  "CompoundAttribute",
                  "String",
                  "Integer",
                  "Decimal",
                  "Boolean",
                ]}
                onChange={(event) => handleTypeChange(event.target.value)}
                errorPlaceholder="Choose Value Type"
                error={errors?.valueType}
              />
            </Grid>
            <Grid item xs={6}>
              <Label label="Format" />
              <CustomSelect
                value={format}
                id="formatType"
                disabled={valueType !== "String"}
                options={formatOptions}
                onChange={(event) => handleFormatChange(event.target.value)}
                errorPlaceholder="Choose Format"
                error={errors?.formatType}
              />
            </Grid>
            <Grid item xs={6}>
              <FormGroup
                placeholder="-"
                value={defaultValue}
                id="defaultValue"
                onChange={handleChange}
                label="Default Value"
                disabled={valueType === ""}
              />
            </Grid>
            <Grid item xs={12}>
              <Label label="Values" disabled={valueType !== "String"} />
              <Tooltip
                title="Please press Enter button after input the value"
                classes={{ popper: "white-tooltip" }}
              >
                <InputGroup>
                  {values?.map((value: string) => (
                    <Chip
                      key={value}
                      label={value}
                      onDelete={() => deleteValue(value)}
                    />
                  ))}
                  <CssTextField
                    fullWidth={!values.length}
                    type="text"
                    variant="outlined"
                    value={valueEntered}
                    id="values"
                    onChange={(e: any) => setValueEntered(e.target.value)}
                    disabled={valueType !== "String"}
                    onKeyDown={handleKeyPress}
                    sx={{ padding: 0, border: "none" }}
                  />
                </InputGroup>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <div
                className="required-checkbox"
                style={{ float: "left", margin: "0 auto" }}
              >
                <FormControlLabel
                  control={<Checkbox onChange={setChecked} />}
                  checked={checked}
                  label="Required Attribute"
                />
                <FormControlLabel
                  control={<Checkbox onChange={setIsArrayed} />}
                  checked={isArrayed}
                  label="isArray"
                />
              </div>
            </Grid>
          </Grid>
        )}
      </Block>
    </Wrapper>
  );
};

export default AttributeForm;
